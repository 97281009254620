import React, { useEffect, useState } from "react";
import { FaDatabase } from "react-icons/fa";
import { clientConnectorActions } from "../../actions/clientConnector.actions";
import Title from "../common/Title";
import Grid from "../common/Grid";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import ConnectorGridFilter from "./ConnectorGridFilter";

function ConnectorGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientConnectorReducer = useSelector((state) => state.clientConnector);

  const filterInitialState = {
    description: "",
    system: "",
    connectorId: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  let actions = [
    {
      text: "Edit",
      url: "edit?connectorId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(clientConnectorActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      system: queryParams["system"],
      connectorId: queryParams["connectorId"],
      description: queryParams["description"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const handleSearch = (filter) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    refreshParams(queryParams);
  };

  const handleClearFilters = () => {
    handleSearch(filterInitialState);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/connector?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  let displayColumns = [
    "connectorId",
    "description",
    "system",
    "loginEndpoint",
    "companyId",
  ];
  if (props.user.isSuperUser) displayColumns.push("clientId");

  const data = clientConnectorReducer.paginatedResult?.data;

  return (
    <>
      <Title text="My Connectors" icon={<FaDatabase className="mb-1" />} />

      <ConnectorGridFilter
        filter={filter}
        onSearch={handleSearch}
        onClearFilters={handleClearFilters}
      />

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"connectorId"}
        actions={actions}
        pageNumber={clientConnectorReducer.paginatedResult?.pageNumber ?? 0}
        totalPages={clientConnectorReducer.paginatedResult?.totalPages ?? 0}
        loading={clientConnectorReducer.loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      ></Grid>
    </>
  );
}

export default ConnectorGrid;
