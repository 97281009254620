import { http } from "../api/http";
import Utils from "../utils";

export const clientConnectorService = {
  get,
  suggestions,
  update,
};

function get(requestParameters) {
  let url = `/clientconnector?${Utils.objectToQueryString(requestParameters)}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function suggestions(requestParameters) {
  let url = `/clientconnector/suggestions?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function update(requestBody) {
  let url = `/clientconnector/update`;

  return http.patch(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}
