import { clientConnectorService } from "../services/clientConnector.service";
import { clientConnectorConstants } from "../constants/clientConnector.constants";

export const clientConnectorActions = {
  get,
  suggestions,
  clearSuggestions,
  update,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientConnectorService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientConnectorConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: clientConnectorConstants.GET_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: clientConnectorConstants.GET_FAILURE };
  }
}

function suggestions(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientConnectorService.suggestions(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientConnectorConstants.GET_SUGGESTIONS_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: clientConnectorConstants.GET_SUGGESTIONS_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: clientConnectorConstants.GET_SUGGESTIONS_FAILURE };
  }
}

function clearSuggestions() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: clientConnectorConstants.CLEAR_SUGGESTIONS_REQUEST,
    };
  }
}

function update(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientConnectorService.update(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientConnectorConstants.UPDATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: clientConnectorConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return {
      type: clientConnectorConstants.UPDATE_FAILURE,
    };
  }
}
